<template>
  <span
    v-for="(string, index) of componentProps.dictionaryStringsArray"
    :key="index"
  >
    <nuxt-link
      v-if="string.includes(TEXT_DIVIDERS.href)"
      :to="validateLocaleRoutePrefix(getLinkData(string).href)"
      :class="[
        'j-link',
        'j-link--main-gradient',
        { 'j-link--pointer-events-none': !getLinkData(string).href },
      ]"
      :target="getLinkData(string).target"
    >
      {{ getLinkData(string).name }}
    </nuxt-link>
    <span v-else>
      {{ string }}
    </span>
  </span>
</template>

<script setup>
import { TEXT_DIVIDERS } from '../constants';

const componentProps = defineProps({
  dictionaryStringsArray: {
    type: Array,
    default: () => [],
  },
});

const isExternalRoute = (route) => /(^(http|https):\/\/)|(\.com$)/.test(route);
const validateLocaleRoutePrefix = (route) => isExternalRoute(route)
  ? route
  : useLocalePrefixForHref(route);

const getLinkData = (string) => {
  const name = /^([^^]+?)\s*\^/g.exec(string)[1];
  const href = string.includes(TEXT_DIVIDERS.href)
    ? /\^href:\^([^^\s]*)/g.exec(string)[1]
    : null;
  const target = string.includes(TEXT_DIVIDERS.target)
    ? /\^target:\^([^^\s]+)/g.exec(string)[1]
    : null;

  return {
    name,
    href,
    target,
  };
};
</script>
