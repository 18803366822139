<template>
  <div
    :style="`max-width: ${textWidth}`"
    :class="[
      'j-text',
      {
        'd-inline-block j-text--fs-32 font-weight-regular': componentProps.textTypes === TEXT_TYPES.title,
        'd-inline-block j-text--main-title font-weight-bold': componentProps.textTypes === TEXT_TYPES.pageTitle,
      },
    ]"
  >
    <h1
      v-if="componentProps.textTypes === TEXT_TYPES.pageTitle"
      class="j-text__container"
    >
      <general-text-parts-text-with-links :dictionary-strings-array="dictionaryStringsArray" />
    </h1>
    <!--TODO: make all text type same logic as for TEXT_TYPES.title-->
    <h2
      v-if="componentProps.textTypes === TEXT_TYPES.title"
      class="j-text__container"
    >
      <general-text-parts-text-with-links :dictionary-strings-array="dictionaryStringsArray" />
    </h2>
    <p
      v-if="componentProps.textTypes === TEXT_TYPES.subTitle"
      class="j-text j-text--fs-16 font-weight-medium"
    >
      <general-text-parts-text-with-links :dictionary-strings-array="dictionaryStringsArray" />
    </p>
    <span v-if="componentProps.textTypes === TEXT_TYPES.regular">
      <general-text-parts-text-with-links :dictionary-strings-array="dictionaryStringsArray" />
    </span>
  </div>
</template>

<script setup>
import { TEXT_TYPES } from '~/constants/general';
import { TEXT_DIVIDERS } from './constants';

const componentProps = defineProps({
  textTypes: {
    type: String,
    default: TEXT_TYPES.regular,
  },
  dictionaryKey: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  textWidth: {
    type: String,
    default: '',
  },
});
const { t } = useI18n();
const dictionaryStringsArray = computed(() => {
  const dictionaryString = (componentProps.text.toString() || t(componentProps.dictionaryKey)).replace(/{'@'}/g, '@');

  return dictionaryString.includes(TEXT_DIVIDERS.link)
    ? dictionaryString.split(TEXT_DIVIDERS.link)
    : [dictionaryString];
});
</script>

<style lang="scss" scoped>
.j-text__container {
  font-size: inherit;
  font-weight: inherit;
}
</style>
