<template>
  <div class="j-info-img-block">
    <div
      :class="[
        'j-info-img-block__description',
        { 'j-info-img-block__description--w-403' : isTextWidth },
      ]"
    >
      <general-text
        v-if="componentProps.dictionaryTitle"
        :text-types="TEXT_TYPES.pageTitle"
        :dictionary-key="componentProps.dictionaryTitle"
        class="pb-4 pr-4 pb-lg-4 mt-7 j-text--white"
      />
      <div
        v-for="(item, index) of componentProps.dictionaryText"
        :key="index"
        class="mb-4"
      >
        <general-text :dictionary-key="item.dictionaryKey" />
      </div>
    </div>

    <div>
      <v-img
        v-if="componentProps.img"
        :lazy-src="useImgSrc(componentProps.img, 'webp')"
        :src="useImgSrc(componentProps.img, 'webp')"
        alt="info"
        class="pt-lg-10"
        width="auto"
        height="auto"
      />
      <v-card
        v-else
        class="j-info-img-block__text pa-6 pb-lg-6 mt-6 mb-lg-6"
        :min-width="widthCard"
        :color="scssVariables.jColorBgBlock"
      >
        <div>
          {{ $t('about_us_address') }}
        </div>
        <div>NetGlobe Services Ltd</div>
        <div>79 Spyrou Kyprianou Avenue</div>
        <div>Protopapas Building</div>
        <div>2-nd floor, office 201</div>
        <div>3076 Limassol, Cyprus</div>
        <div class="pb-7">
          Registration number HE 313864
        </div>
        <div>
          <general-text dictionary-key="about_us_support" />
        </div>
        <div>
          <general-text dictionary-key="about_us_write_director" />
        </div>
      </v-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { TEXT_TYPES } from '~/constants/general';

const scssVariables = useScssVariables();
const isMobileMode = inject<Ref<boolean>>('isMobileMode');
const widthCard = computed(() =>  isMobileMode?.value
  ? '328'
  : '550',
);
const componentProps = defineProps({
    img: {
        type: String,
        default: '',
    },
    dictionaryTitle: {
        type: String,
        default: '',
    },
    dictionaryText: {
        type: Array as PropType<{[key: string]: string}[]>,
        default: () => [],
    },
    widthImg: {
        type: Number,
        default: null,
    },
    isTextWidth: {
      type: Boolean,
      default: false,
    },
});
</script>

<style scoped lang="scss">
.j-info-img-block__description {
  &.j-info-img-block__description--w-403 {
    @media (min-width: 1280px) {
      width: 403px;
    }
  }
}

.j-info-img-block__text {
    font-size: 14px;
    color: $j-color-text-secondary;
}
</style>
