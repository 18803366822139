<template>
  <div class="j-about-us">
    <general-info-and-img-block
      :img="boxImg.src"
      :dictionary-title="DESCRIPTION_TEXT.aboutProjectTextList.title"
      :dictionary-text="DESCRIPTION_TEXT.aboutProjectTextList.textList"
      :is-text-width="true"
      class="pb-4 d-flex flex-column flex-lg-row justify-lg-space-between align-center mb-lg-10"
    />
    <general-info-and-img-block
      :img="girlImg.src"
      :dictionary-title="DESCRIPTION_TEXT.securityText.title"
      :dictionary-text="DESCRIPTION_TEXT.securityText.textList"
      :is-text-width="true"
      class="flex-lg-row-reverse mb-14 d-flex flex-column flex-lg-row justify-lg-space-between align-center mb-lg-10"
    />
    <v-divider />
    <general-info-and-img-block
      :img="bonusImg.src"
      :dictionary-title="DESCRIPTION_TEXT.bonusText.title"
      :dictionary-text="DESCRIPTION_TEXT.bonusText.textList"
      class="d-flex flex-column align-center text-lg-center mt-14 mt-lg-16"
    />

    <general-info-and-img-block
      :dictionary-title="DESCRIPTION_TEXT.howStartText.title"
      :dictionary-text="DESCRIPTION_TEXT.howStartText.textList"
      :is-text-width="true"
      class="mb-14 d-flex flex-column flex-lg-row justify-space-between align-center justify-center mb-lg-10 pl-lg-10"
    />
  </div>
</template>
<script setup lang="ts">
import {
  DESCRIPTION_TEXT,
  ABOUT_US_IMAGES,
} from '~/pages/about-us/constants';

definePageMeta({ middleware: 'ensure-canonical-available' });
const { t } = useI18n();

usePageMeta(t('about_us_page_title'), t('about_us_page_description'));
const isMobileMode = inject<Ref<boolean>>('isMobileMode');
const boxImg = computed(() => {
  const isMobileDisplayValue = isMobileMode?.value;
  const src = isMobileDisplayValue
    ? '/images/staticPages/aboutUs/boxImgMobile.png'
    : '/images/staticPages/aboutUs/boxImgDesktop.png';
  const width = isMobileDisplayValue
    ? ABOUT_US_IMAGES.boxImg.imgWidth.mobile
    : ABOUT_US_IMAGES.boxImg.imgWidth.desktop;

  return {
    src,
    width,
  };
});

const girlImg = computed(() => {
  const isMobileDisplayValue = isMobileMode?.value;
  const src = isMobileDisplayValue
    ? '/images/staticPages/aboutUs/girlImgMobile.png'
    : '/images/staticPages/aboutUs/girlImgDesktop.png';
  const width = isMobileDisplayValue
    ? ABOUT_US_IMAGES.girlImg.imgWidth.mobile
    : ABOUT_US_IMAGES.girlImg.imgWidth.desktop;

  return {
    src,
    width,
  };
});

const bonusImg = computed(() => {
  const isMobileDisplayValue = isMobileMode?.value;
  const src = isMobileDisplayValue
    ? '/images/staticPages/aboutUs/bonusImgMobile.png'
    : '/images/staticPages/aboutUs/bonusImgDesktop.png';
  const width = isMobileDisplayValue
    ? ABOUT_US_IMAGES.aboutUsBanner.imgWidth.mobile
    : ABOUT_US_IMAGES.aboutUsBanner.imgWidth.desktop;

  return {
    src,
    width,
  };
});
</script>
